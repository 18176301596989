<template>
  <div>
    <DiscountTypeFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="setFilterData"
    />
    <div v-if="!isLoading && isEmpty(discountTypes)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else-if="!isLoading && !isEmpty(discountTypes)">
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(type, index) in discountTypes" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Title', ['Title'])">
            {{ type.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Description')">
            {{ type.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :idx="index"
              :current-user="type"
              :action-list="actionList"
              @action="typeAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <div v-else class="mt-28">
      <Loader :content="true" />
    </div>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(discountTypesCounts, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />
    <DiscountTypesModal
      v-if="showModal"
      :modal="showModal"
      :edit="currentRecord"
      @toggle="toggleModal"
    ></DiscountTypesModal>
  </div>
</template>

<script>
import { objectDeepCopy, showColumnInDataTable } from '@/src/utils/generalUtil.js'
import DiscountTypeFilter from '@src/router/views/finance/fees/discount/DiscountTypesFilter.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import discountTypeData from '@/src/router/views/finance/fees/discount/studentDiscountList.json'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import fileMixins from '@/src/mixins/file-mixins'
import GeneralUtil from '@src/mixins/general-mixins.js'
import isEmpty from 'lodash/isEmpty'
import Loader from '@components/BaseComponent/Loader.vue'
import DiscountTypesModal from '@/src/router/views/finance/fees/modals/DiscountTypesModal.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    DiscountTypeFilter,
    TableAction,
    Pagination,
    Loader,
    DiscountTypesModal,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralUtil, fileMixins, scrollMixin],

  data() {
    return {
      isEmpty,
      GENERAL_CONSTANTS,
      isLoading: false,
      fees: 'fees',
      dashboard: 'dashboard',
      currentRecord: null,
      showPagination: false,
      filtersData: {},
      filteredRecordLimit: GENERAL_CONSTANTS.RECORD_LIMIT,
      skipForFilterColumns: discountTypeData.discountTypeSkipForFilterColumns,
      defaultSelectedColumns: discountTypeData.discountTypeDefaultSelectedColumns,
      actionList: [{ name: 'Edit' }],
      tableHeaders: discountTypeData.discountTypeTableHeaders,
      discountType: {
        title: '',
        description: '',
      },
    }
  },
  computed: {
    ...mapState({
      showModal: (state) => state.layout.showModal,
      discountTypes: (state) => state.fee.discountTypes?.records || [],
      discountTypesCounts: (state) => state.fee.discountTypes?.meta?.total_records || 0,
    }),
  },
  /**
   * Mounted hook
   * @description Setting static right bar data
   */
  mounted() {
    this.setRightbarData(this.discountTypes.length)
    this.filterRecord()
  },
  methods: {
    showColumnInDataTable,
    setFilterData(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
    },
    ...mapActions('layout', ['setRightbarContent', 'setShowModal']),
    ...mapActions('fee', ['addOrUpdateDiscountType', 'getDiscountTypes']),

    /**
     * Type Action
     * @param {string} eventName
     * @param {object} currentType
     * @param {number} idx
     * @returns {void}
     * @description Edit or delete current type on the basis of action
     */
    typeAction(action, currentType, idx) {
      this.currentRecord = currentType
      switch (action) {
        case 'Edit':
          this.toggleModal()
          break
      }
    },
    /**
     * Add Discount Types
     * @param {boolean} invalid
     * @returns {void}
     * @description Method description
     * -  Edit existing discount type
     * -  Show toast regarding to action we perform eg:Create or Edit
     * -  Setting right bar data
     */
    filterRecord(range) {
      this.isLoading = true
      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getDiscountTypes(payload).then(() => {
        this.isLoading = false
        this.showPagination = this.discountTypesCounts > 10
        this.setRightbarData(this.discountTypesCounts)
      })
    },

    toggleModal(payload = null) {
      this.setShowModal()
      if (payload) this.filterRecord()
      if (!this.showModal) this.currentRecord = null
    },

    setRightbarData(length) {
      const rightBarContent = {
        header: {
          title: 'DISCOUNT_TYPE',
          buttons: [
            {
              title: 'ADD_DISCOUNT_TYPE',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: {} },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'DISCOUNT_TYPE',
                value: length || 0,
                tooltipDescription: 'Total No. of Discount Types',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
