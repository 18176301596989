<template>
  <ValidationObserver @submit="formCheck">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-i18n="fees">
          <span v-if="!edit">ADD_DISCOUNT_TYPE</span>
          <span v-else>EDIT_DISCOUNT_TYPE</span>
        </span>
      </template>
      <template v-slot>
        <div>
          <InputFieldWrapper>
            <InputBox
              v-model.trim="discountType.title"
              type="text"
              name="DISC_TITLE"
              title="DISC_TITLE"
              label="Discount Title"
              placeholder="Discount Title"
              :class="$style.widthItems"
              class="flex-1"
              rules="required"
            />
            <InputBox
              v-model="discountType.description"
              type="text"
              name="Description"
              title="Description"
              label="Description"
              placeholder="Description"
              :class="$style.widthItems"
              class="flex-1"
            />
          </InputFieldWrapper>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" @click="changeDate()">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              <span v-if="!edit">Save</span>
              <span v-else>Update</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import InputBox from '@components/UiElements/UiInputBox.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { mapActions } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiModalContainer,
    ValidationObserver,
    InputBox,
    UIButton,
    Loader,
    InputFieldWrapper,
  },
  props: {
    modal: { type: Boolean, default: false },
    edit: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['toggle'],
  data() {
    return {
      isLoading: false,
      fees: 'fees',
      dashboard: 'dashboard',
      discountType: {
        title: '',
        description: '',
      },
    }
  },

  created() {
    if (this.edit) {
      this.discountType = {
        title: this.edit?.title,
        description: this.edit?.description,
      }
    }
  },
  methods: {
    ...mapActions('fee', ['addOrUpdateDiscountType']),
    ...mapActions('layout', ['setShowModal']),

    /**
     * Handle click
     * @param {string} eventName
     * @returns {void}
     * @description Calling setShowModal and resetForm method on the basis of event name
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          this.resetForm()
          break
        case 'cancel':
          break
        case 'save':
          break
      }
    },
    /**
     * Reset Form
     * @description Resetting discount type name and description to empty value
     */
    resetForm() {
      this.discountType.title = ''
      this.discountType.description = ''
    },
    formCheck() {
      this.isLoading = true
      const data = {
        discount: this.discountType,
      }
      if (this.edit) data.id = this.edit?.id
      this.addOrUpdateDiscountType(data)
        .then((res) => {
          if (this.edit)
            this.$store.commit('toast/NEW', {
              type: 'success',
              message: `${res?.data?.title} ${this.$t('toast.discount type')} ${this.$t(
                'toast.is updated successfully',
              )}`,
            })
          else
            this.$store.commit('toast/NEW', {
              type: 'success',
              message: `${this.$t('toast.New discount type')}  ${res?.data?.title} ${this.$t(
                'toast.is added successfully',
              )}`,
            })

          this.$emit('toggle', true)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
