<template>
  <div>
    <TitleWrapper
      :filter-option="true"
      title="DISC_TYPE"
      tooltip-title="DISC_TYPE_TOOLTIP"
      :display-breadcrumb="true"
      :displayed-column-options="csvFilterOptions"
      :already-selected-displayed-column="filtersPayload.displayedColumns"
      :count="filtersCount"
      @cancel="cancelHanlder"
      @applyFilter="emitFiltersPayload($event, invalid)"
      @clear="clearFilters"
    >
      <template v-slot:filterItems>
        <InputFieldWrapper>
          <UiInput
            v-model.trim="filtersPayload.title"
            class="flex-1"
            title="TITLE"
            type="text"
            placeholder="TITLE"
            rules="alpha"
            :filter="FILTERS_LIST.TITLE"
            @emitQuery="updateQuery"
          />
        </InputFieldWrapper>
      </template>
    </TitleWrapper>
  </div>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import TitleWrapper from '@src/components/TitleWrapper.vue'
import { mapActions, mapState } from 'vuex'
import { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { getCountFromObject } from '@utils/generalUtil'
import filterMixins from '@/src/mixins/filter-mixins.js'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
export default {
  components: {
    UiInput,
    TitleWrapper,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      FILTERS_LIST,
      filtersCount: 0,
      filtersPayload: {
        title: '',
        displayedColumns: [],
      },
    }
  },
  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope || null,
    }),
  },

  watch: {
    currentCampusScope: {
      handler() {
        this.clearFilters()
      },
    },
    currentSectionScope: {
      handler() {
        this.clearFilters()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
  },
  methods: {
    getFiltersCount() {
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
  },
}
</script>
